@media (min-width: 400px) {
    .ui.secondary.pointing.menu {
        display: table;
        width: 100%;
    }

    .ui.secondary.pointing.menu .item{
        display: table-cell;
        text-align: center;
    }
}
