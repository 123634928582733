.ninpath__card__header{
  font-size: 2.2rem;
  padding: 14px;
  border-bottom: none;
}

.ninpath__filter{
  width:70%;
}

@media (max-width: 640px){
  .ninpath__card__header{
    font-size: 1.9rem;
    padding: 12px 10px;
  }
}
@media (max-width: 500px){
  .ninpath__filter{
    width:200px;
  }
}

.chartjs-render-monitor{
  padding-left: 5px;
}

.ninpath__card__parameters{
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  border-top: none;
  border-bottom: none;
}
