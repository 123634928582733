body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  box-sizing: inherit;
  /* -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: blur;
  height: 100%;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.centered__block {
  margin: auto;
  -ms-transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
}
.container {
  width: 100% !important;
}

/* FONTS */
.font__heading {
  text-align: center;
  font-size: 1.125rem !important;
}

.font__large {
  font-size: 1.5rem !important;
}

.font__huge {
  font-size: 2.5rem !important;
}

.button__container {
  display: flex;
  flex-direction: column;
  padding: 50px;
}

.primary {
  width: 100%;
}

.ui.container {
  margin: 0px !important;
}

.grid {
  width: 100vw;
  margin: 0 !important;
}

.section {
  color: #000 !important;
}

.graph_container {
  height: 50vh;
  width: 50vw;
}

.ninpath__card .dropdown.icon,
.ninpath__card .ui.selection.dropdown {
  padding-right: 0px !important;
  padding-top: 0px !important;
  text-align: center;
}

.ui.dropdown > .text {
  text-overflow: ellipsis;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
}

.card__registration .content {
  padding: 0 !important;
}

.card__registration .fields {
  margin: 0 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.card__registration .date__group {
  padding-right: 0px !important;
  padding-left: 0px !important;
  /* min-width: 100%; */
  display: flex;
  width: 50% !important;
}

.card__registration .button_form_container .field {
  display: flex;
  justify-content: center;
  margin-bottom: 14px !important;
}

.card__registration .ui.form .fields .wide.field {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.card__registration .ui.form .field > label {
  font-weight: 500;
}

.form__email{
  width:100%;
}
.card__registration .form__email label,
.card__registration .form__password label {
  padding-left: 0.3em;
  width: 100% !important;
}
.card__registration .wide.field {
  padding-left: 0 !important;
}

.card__registration .form__email .input,
.card__registration .form__password .input {
  padding-left: 0.3em;
}
.card__registration .form__password .input input{
  text-align: right;
}

.card__registration .field {
  padding-right: 0 !important;
  padding-left: 0.3em;
}

.card__registration .terms {
  margin-bottom: 14px;
}
.card__registration .terms .field {
  text-align: center;
}

.ui.modal .cancel_button {
  background-color: #f2f2f2 !important;
  color: #000;
}

.ui.modal .confirmation_button {
  background-color: #d65358 !important;
  color: #fff;
}

.ninpath__card table,
.treatment__cycles table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.treatment__cycles thead > tr:nth-child(1) > th {
  white-space: nowrap;
  text-align: center;
  font-weight: 500;
}

.treatment__cycles tbody > tr > td {
  text-align: center;
  border-bottom: 1px solid #f2f2f2;
}

.treatment__cycles tbody > tr > td:nth-child(1) {
  padding: 5px;
}
.treatment__cycles tbody > tr > td:nth-child(1) > div {
  border: 1px solid #d65358;
  border-radius: 2px;
  padding: 10px;
}

.ninpath__card td,
.ninpath__card th {
  padding: 8px;
  color: #464646;
}
.ninpath__card thead > tr:nth-child(1) {
  background-color: #eff1f4;
}
.ninpath__card thead > tr:nth-child(1) > th {
  white-space: nowrap;
  text-align: center;
}

.ninpath__card tbody > tr > td:nth-child(6) {
  display: flex;
}
.ninpath__card tbody > tr > td:nth-child(1) {
  white-space: nowrap;
}
.ninpath__card tbody > tr > td:nth-child(2) {
  white-space: nowrap;
}

.ninpath__card td.opu_column {
  display: table-cell !important;
  min-width: 100px;
  white-space: nowrap !important;
}

.ninpath__card td,
th {
  border-left: none;
  border-right: none;
  border-top: none;
}
.opu__card .selection {
  min-width: auto !important;
}

.opu__card .column {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.opu__card .field {
  width: 100%;
}

.opu__card .button_form_container .field {
  display: flex;
  justify-content: center;
}

.opu__card input {
  width: 100% !important;
  text-align: right !important;
}

.opu__card .ui.icon.input {
  width: 100% !important;
}
.opu__card .selection.dropdown {
  text-align: right !important;
}
.opu__card .ui.multiple.search.dropdown > .text {
  right: 0 !important;
}
.error {
  margin-top: 0px !important;
  width: 100%;
}

.error_button {
  width: 200px;
  margin-bottom: 14px !important;
  font-weight: 500 !important;
}

.ui.secondary.pointing.menu .active.item {
  border-color: #d65358;
}
.ui.secondary.pointing.menu .active.item:hover {
  border-color: #d65358;
}

.ninpath__button__main {
  background-color: #d65358 !important;
  color: #fff !important;
}

.top__page__button__sub {
  background-color: #d65358;
  color: #000;
}

.button_form_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.ninpath__form__terms__of__service{
  color: #4183c4 !important;
  border-bottom: 1px solid #4183c4;
}

.ninpath__form__container .button {
  background-color: #d65358 !important;
  color: #fff !important;
}

.ninpath__form__container_sub .button {
  background-color: #f2f2f2 !important;
}

.top__page__button__section {
  height: 40vh;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.top__page__image {
  height: 60vh;
  object-fit: cover;
}
.button__container .top__page__button__sub {
  font-weight: 500;
  background: transparent;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
  -webkit-box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
}
.card__registration .row {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.card__registration .column {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.opu__card input {
  width: 50% !important;
}
.card__registration input {
  width: 50% !important;
}
.opu__card label {
  width: 50% !important;
}
.card__registration label {
  width: 50% !important;
}
.card__registration .selection {
  width: 50% !important;
}
.opu__card .ui.selection.dropdown {
  text-align: right;
  min-width: 100%;
}
.card__registration .ui.selection.dropdown {
  text-align: right !important;
  min-width: 100% !important;
}
.card__registration .ui.selection.dropdown.date {
  padding-left: 0px !important;
  min-width: 30% !important;
}
#birthday {
  text-align: right;
}
#spouse_birthday {
  text-align: right;
}
.opu__card .ninpath__select {
  width: 50% !important;
  text-align: right !important;
}
.card__registration .ninpath__select {
  width: 50% !important;
  text-align: right !important;
}
.opu__card .form__date {
  width: 50% !important;
  padding-left: 0px !important;
}
.card__registration .form__date {
  width: 50% !important;
  padding-left: 0px !important;
}
.form__email > .input > input {
  margin-bottom: 14px;
  text-align: right !important;
}
.ui.checkbox input.hidden + label {
  width: 100% !important;
}
.confirmation__error {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}
.ui.form .inline.field > :first-child,
.ui.form .inline.fields .field > :first-child {
  margin-right: 0;
}
.ninpath__sidebar {
  width: 75% !important;
  position: relative;
}
.opu__card input.search {
  width: 100% !important;
}

.ninpath__card .ninpath__filter__list .ui.dropdown > .text {
  margin-right: 15px;
}

.drug_usage_advice {
  font-size: 1.3rem;
}

.column_multiline {
  white-space: normal !important;
}
#drugs_usage_form .field {
  padding-right: 0 !important;
}
.search_filter .field {
  width: 100% !important;
}
#change_user__form__email {
  margin-bottom: 14px !important;
}
@media (max-width: 640px) {
  #user__form__email {
    margin-bottom: 14px !important;
  }
  .drug_usage_advice {
    font-size: 1rem;
  }
  .graph_container {
    width: 100vw;
  }
  .button__container {
    width: 100%;
  }
  .button__container .ui.button {
    width: 100%;
  }
  .search_filter .fields {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
@media (min-width: 640px) {
  .button__container {
    max-width: 60%;
    min-width: 50%;
    justify-content: center;
  }
  .button__container .ui.button {
    max-width: 100%;
  }
}
.card__registration #amh {
  width: 100% !important;
}
@media (min-width: 768px) {
  .card__registration .date__group {
    width: 60% !important;
  }
  .ninpath__sidebar {
    width: 30% !important;
  }
  .opu__card label {
    width: 40% !important;
  }
  .card__registration label {
    width: 40% !important;
  }
  .card__registration label#radio {
    width: 50% !important;
  }
  .opu__card input {
    width: 60% !important;
  }
  .card__registration input {
    width: 60% !important;
  }
  .opu__card .ninpath__select {
    width: 60% !important;
  }
  .card__registration .ninpath__select {
    width: 60% !important;
  }
  .opu__card .form__date {
    width: 60% !important;
  }
  .card__registration .form__date {
    width: 60% !important;
  }
  #email__confirmation {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #password__confirmation {
    display: flex;
    margin-top: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  #amh__container {
    margin-right: 0 !important;
  }
}
@media (max-width: 992px) {
  .form__email__confirmation {
    margin-bottom: 0px;
  }
  .form__password__confirmation {
    margin-bottom: 0px;
  }
}
@media (min-width: 1025px) {
  .top__page__button__section {
    height: auto;
    position: absolute;
    top: 60%;
    justify-content: center;
    align-items: center;
  }
  .top__page__image {
    height: 100vh;
  }
  .login__page__button__container {
    max-width: 50%;
    margin: auto !important;
    margin-top: 16px !important;
  }
  .button__container {
    min-width: 30%;
    max-width: 50%;
  }
  .button__container .top__page__button__sub {
    background: #f2f2f2;
  }
}

.clinic__form input {
  text-align: right !important;
}
.clinic__form input.search {
  text-align: right !important;
}
