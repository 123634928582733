@charset "UTF-8";
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, button, textarea, select, option {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input::-ms-clear {
  display: none;
}

input::-ms-reveal {
  display: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a, a:hover, a:visited, a:active, a:focus, a:focus-within {
  color: inherit;
}

ul {
  list-style: none;
}

img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

main {
  display: block;
}

.site-frame {
  position: relative;
}

.site-frame-inner {
  min-width: 1200px;
  margin: 0 auto;
  left: 0;
  top: 0;
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

@media screen and (max-width: 900px) {
  .site-frame-inner {
    min-width: 0;
    position: static;
    width: 100%;
    overflow: hidden;
  }
}

.site-frame-inner::after {
  content: '';
  display: block;
  width: 5px;
  height: 100%;
  background: #d65259;
  position: absolute;
  left: 4.41176%;
  top: 0;
  z-index: 2;
}

@media screen and (max-width: 900px) {
  .site-frame-inner::after {
    left: 0;
  }
}

.sp_none {
  display: block;
}

@media screen and (max-width: 900px) {
  .sp_none {
    display: none;
  }
}

.pc_none {
  display: none;
}

@media screen and (max-width: 900px) {
  .pc_none {
    display: block;
  }
}

.js-anime[data-anime="fade"] {
  opacity: 0;;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: opacity .5s ease, -webkit-transform .5s ease;
  transition: opacity .5s ease, -webkit-transform .5s ease;
  transition: opacity .5s ease, transform .5s ease;
  transition: opacity .5s ease, transform .5s ease, -webkit-transform .5s ease;
}

.js-anime[data-anime="fade"].is-active {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.footer {
  background: #76d1d9;
  padding: 65px 0 70px;
}

@media screen and (max-width: 900px) {
  .footer {
    padding: 25px 0;
  }
}

.footer .footer-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .footer .footer-inner {
    display: block;
  }
}

@media screen and (min-width: 901px) {
  .footer .footer-inner .logo {
    padding-right: 30px;
  }
}

@media screen and (max-width: 900px) {
  .footer .footer-inner .logo {
    margin-bottom: 15px;
    text-align: center;
  }
}

.footer .footer-inner .logo img {
  width: 198px;
}

@media screen and (max-width: 900px) {
  .footer .footer-inner .logo img {
    width: 140px;
  }
}

.footer .footer-inner .address {
  color: #fff;
}

@media screen and (min-width: 901px) {
  .footer .footer-inner .address {
    padding-left: 30px;
    border-left: 1px solid #fff;
  }
}

@media screen and (max-width: 900px) {
  .footer .footer-inner .address {
    font-size: 12px;
    text-align: center;
  }
}

.footer .footer-inner .address .bold {
  font-weight: bold;
  margin-bottom: 15px;
}

@media screen and (max-width: 900px) {
  .footer .footer-inner .address .bold {
    margin-bottom: 10px;
  }
}

.footer .footer-inner .terms {
  color: #fff;
}

@media screen and (min-width: 901px) {
  .footer .footer-inner .terms {
    margin-left: 30px;
    padding-left: 30px;
    border-left: 1px solid #fff;
  }
}

@media screen and (max-width: 900px) {
  .footer .footer-inner .terms {
    padding-top: 15px;
    font-size: 12px;
    text-align: center;
  }
}

.footer .app-btn-bottom {
  text-align: center;
  margin-top: 20px;
}

.footer .app-btn-bottom a {
  margin: 20px;
}

@media screen and (max-width: 900px) {
  .top-banner-frame {
    height: 50px;
    background-color: #F2F2F2;
  }
  .top-banner-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .top-banner-inner .close {
    display: block;
    position: absolute;
    left: 4px;
    top: 8px;
  }
  .top-banner-inner .app-icon {
    display: block;
    position: absolute;
    top: 2px;
    left: 28px;
    height: 46px;
    border-radius: 14px/14px;
  }
  .top-banner-inner .description {
    display: block;
    position: relative;
    left: 80px;
  }
  .top-banner-inner .btn {
    display: block;
    position: absolute;
    right: 5px;
    top: 8px;
    border-radius: 14px/50%;
    border-bottom: 3px solid #a11;
    background: #d65259;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .top-banner-inner p {
    display: block;
    position: absolute;
    left: 60px;
    top: 10px;
    font-size: 0.8em;
  }
}

.header .header-inner {
  position: relative;
  overflow: hidden;
  padding-bottom: 60px;
}

@media screen and (max-width: 900px) {
  .header .header-inner {
    padding-bottom: 25px;
  }
}

.header .header-inner .sumaho {
  display: block;
  width: 32.82353%;
  background: url(../img/header/mainvisual_parts.png) no-repeat center/cover;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}

@media screen and (min-width: 901px) {
  .header .header-inner .sumaho::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 205.55058%;
  }
}

@media screen and (max-width: 900px) {
  .header .header-inner .sumaho {
    width: 104px;
    height: 283px;
    background-image: url(../img/header/mainvisual_parts.sp.png);
  }
}

.header .header-inner .compass {
  display: block;
  width: 24.35294%;
  background: url(../img/header/compass.png) no-repeat center/cover;
  position: absolute;
  left: 0;
  bottom: -170px;
}

@media screen and (min-width: 901px) {
  .header .header-inner .compass::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 120.86852%;
  }
}

@media screen and (max-width: 900px) {
  .header .header-inner .compass {
    display: none;
  }
}

.header .header-bar {
  width: 100%;
  height: 125px;
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 7.35294%;
  z-index: 2;
}

@media screen and (max-width: 900px) {
  .header .header-bar {
    position: static;
    height: 55px;
    padding-left: 25px;
    background: #fff;
  }
}

.header .header-bar::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

@media screen and (max-width: 900px) {
  .header .header-bar::before {
    display: none;
  }
}

.header .header-bar .header-bar-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3;
}

.header .header-bar .logo {
  width: 190px;
}

@media screen and (max-width: 900px) {
  .header .header-bar .logo {
    width: 90px;
  }
}

.header .header-bar .navi {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 27.94118%;
}

@media screen and (max-width: 1650px) {
  .header .header-bar .navi {
    right: 32.35294%;
  }
}

@media screen and (max-width: 1500px) {
  .header .header-bar .navi {
    right: 38.23529%;
  }
}

@media screen and (max-width: 900px) {
  .header .header-bar .navi {
    display: none;
  }
}

.header .header-bar .navi .navi-item {
  cursor: pointer;
}

.header .header-bar .navi .navi-item:not(:first-child) {
  margin-left: 50px;
}

@media screen and (min-width: 1200px) {
  .header .header-bar .navi .navi-item {
    font-size: calc(0.875rem + ((1vw - 12px) * 0.4));
  }
}

@media screen and (min-width: 1700px) {
  .header .header-bar .navi .navi-item {
    font-size: 16px;
  }
}

@media screen and (max-width: 1199px) {
  .header .header-bar .navi .navi-item {
    font-size: 14px;
  }
}

.header .header-bar .shine {
  display: block;
  width: 38.94118%;
  background: url(../img/header/shine.png) no-repeat center/cover;
  position: absolute;
  left: 0;
  top: 8%;
  z-index: 1;
}

@media screen and (min-width: 901px) {
  .header .header-bar .shine::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 106.26415%;
  }
}

@media screen and (max-width: 900px) {
  .header .header-bar .shine {
    display: none;
  }
}

.header .regist-login {
  width: 335px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .header .regist-login {
    width: 312px;
  }
}

@media screen and (min-width: 901px) {
  .header .regist-login.sp-none {
    position: absolute;
    top: 35px;
    right: 5.88235%;
    z-index: 3;
  }
}

@media screen and (max-width: 900px) {
  .header .regist-login.sp-none {
    display: none;
  }
}

.header .regist-login.pc-none {
  display: none;
}

@media screen and (max-width: 900px) {
  .header .regist-login.pc-none {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
  }
}

.header .regist-login::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: url(../img/header/regist_login_bg.png) no-repeat center/cover;
  z-index: 1;
}

@media screen and (max-width: 900px) {
  .header .regist-login::after {
    background-image: url(../img/header/regist_login_bg.sp.png);
    background-size: contain;
  }
}

.header .regist-login .regist-btn {
  background: #d6b71d;
  width: 160px;
  height: 45px;
  border-radius: 22px 0 0 22px;
  position: relative;
  z-index: 2;
  -webkit-transition: background-color .3s ease;
  transition: background-color .3s ease;
}

@media screen and (min-width: 901px) {
  .header .regist-login .regist-btn:hover {
    background: #efca11;
  }
}

@media screen and (max-width: 900px) {
  .header .regist-login .regist-btn {
    width: 150px;
    height: 35px;
    border-radius: 17px 0 0 17px;
  }
}

.header .regist-login .regist-btn .link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 1rem;
}

@media screen and (max-width: 900px) {
  .header .regist-login .regist-btn .link {
    font-size: 14px;
  }
}

.header .regist-login .regist-btn .link::before {
  content: '';
  display: block;
  width: 21px;
  height: 16px;
  background: url(../img/header/regist_mark.png) no-repeat center/cover;
  margin-right: 10px;
}

@media screen and (max-width: 900px) {
  .header .regist-login .regist-btn .link::before {
    width: 20px;
    height: 15px;
  }
}

.header .regist-login .login-btn {
  background: #fff;
  width: 160px;
  height: 45px;
  border: 3px solid #d6b71d;
  border-radius: 0 22px 22px 0;
  position: relative;
  z-index: 2;
  -webkit-transition: border-color .3s ease;
  transition: border-color .3s ease;
}

@media screen and (min-width: 901px) {
  .header .regist-login .login-btn:hover {
    border-color: #efca11;
  }
  .header .regist-login .login-btn:hover .link {
    color: #efca11;
  }
  .header .regist-login .login-btn:hover .link::before {
    background-image: url(../img/header/login_mark_h.png);
  }
}

@media screen and (max-width: 900px) {
  .header .regist-login .login-btn {
    width: 150px;
    height: 35px;
    border-radius: 0 17px 17px 0;
  }
}

.header .regist-login .login-btn .link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #d6b71d;
  -webkit-transition: color .3s ease;
  transition: color .3s ease;
}

@media screen and (max-width: 900px) {
  .header .regist-login .login-btn .link {
    font-size: 14px;
  }
}

.header .regist-login .login-btn .link::before {
  content: '';
  display: block;
  width: 17px;
  height: 18px;
  background: url(../img/header/login_mark.png) no-repeat center/cover;
  margin-right: 10px;
  -webkit-transition: background-image .3s ease;
  transition: background-image .3s ease;
}

@media screen and (max-width: 900px) {
  .header .regist-login .login-btn .link::before {
    width: 15px;
    height: 16px;
  }
}

.header .main-visual {
  padding-top: 15%;
  padding-left: 14.70588%;
  position: relative;
}

@media screen and (max-width: 900px) {
  .header .main-visual {
    padding: 0;
  }
}

.header .main-visual .visual {
  width: 100%;
  background: url(../img/header/main_visual.png) no-repeat center/cover;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

@media screen and (min-width: 901px) {
  .header .main-visual .visual::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 42.44118%;
  }
}

@media screen and (max-width: 900px) {
  .header .main-visual .visual {
    position: relative;
    padding-top: 45px;
    padding-left: 50px;
    height: 219px;
    background-image: url(../img/header/main_visual.sp.png);
    z-index: 2;
  }
}

.header .main-visual .catch-copy {
  width: 35.17647%;
  margin-bottom: 17.64706%;
}

@media screen and (max-width: 900px) {
  .header .main-visual .catch-copy {
    width: 196px;
    margin-bottom: 70px;
    position: absolute;
    top: 45px;
    left: 50px;
    z-index: 2;
  }
}

.header .main-visual .lead-area {
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 900px) {
  .header .main-visual .lead-area {
    position: static;
  }
}

.header .main-visual .lead-text {
  font-weight: 900;
  line-height: 1.55556;
}

@media screen and (min-width: 901px) {
  .header .main-visual .lead-text {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 901px) and (min-width: 1200px) {
  .header .main-visual .lead-text {
    font-size: calc(1.875rem + ((1vw - 12px) * 3));
  }
}

@media screen and (min-width: 901px) and (min-width: 1700px) {
  .header .main-visual .lead-text {
    font-size: 45px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1199px) {
  .header .main-visual .lead-text {
    font-size: 30px;
  }
}

@media screen and (max-width: 900px) {
  .header .main-visual .lead-text {
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
  }
}

@media screen and (min-width: 901px) {
  .header .main-visual .text {
    font-size: 1.125rem;
    line-height: 2.27778;
  }
}

@media screen and (min-width: 901px) and (min-width: 1200px) {
  .header .main-visual .text {
    font-size: calc(0.875rem + ((1vw - 12px) * 0.8));
  }
}

@media screen and (min-width: 901px) and (min-width: 1700px) {
  .header .main-visual .text {
    font-size: 18px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1199px) {
  .header .main-visual .text {
    font-size: 14px;
  }
}

@media screen and (max-width: 900px) {
  .header .main-visual .text {
    font-size: 12px;
    padding: 0 8%;
    margin-top: 15px;
    margin-bottom: 25px;
  }
}

.news {
  background: #f6f6f6;
  padding: 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.news ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.news li {
  font-size: 1.2rem;
  border-bottom: solid 1px #DBDBDB;
}

.news .news-ttl {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 2rem;
}

.news .inner {
  width: 90%;
  max-width: 900px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.news .new-mark {
  margin-left: 4px;
  color: #d65259;
  font-size: 0.8em;
}

.news .news-area {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.news .news-area .link {
  display: block;
}

.news .news-block .news-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}

.news-all {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 25px;
}

@media screen and (min-width: 901px) {
  .news .news-area {
    margin-left: 25px;
  }
  .news .news-area .link {
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
  }
  .news .news-area .link:hover {
    opacity: 0.7;
  }
  .news .news-block .news-header .date {
    display: inline-block;
    width: 160px;
  }
  .news .news-block .news-header-ttl {
    display: inline-block;
    margin-left: 0;
    position: absolute;
  }
}

@media screen and (max-width: 900px) {
  .news {
    padding: 20px 0;
  }
  .news .inner {
    width: 100%;
  }
  .news .news-area {
    padding-left: 8%;
    padding-right: 8%;
  }
  .news .news-block .news-header {
    margin-bottom: 10px;
  }
  .news .news-block .news-header .text-wrapper {
    font-size: 1rem;
  }
}

.three-point {
  padding-top: 8.82353%;
  padding-bottom: 14.70588%;
  position: relative;
}

@media screen and (max-width: 900px) {
  .three-point {
    padding-top: 35px;
    padding-bottom: 75px;
  }
}

.three-point .bg01 {
  width: 48%;
  background: url(../img/top/point_bg01.png) no-repeat center/cover;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

@media screen and (min-width: 901px) {
  .three-point .bg01::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 66.66667%;
  }
}

@media screen and (max-width: 900px) {
  .three-point .bg01 {
    width: 245px;
    height: 163px;
    top: 20px;
  }
}

.three-point .bg02 {
  width: 49%;
  background: url(../img/top/point_bg02.png) no-repeat center/cover;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}

@media screen and (min-width: 901px) {
  .three-point .bg02::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 80.79232%;
  }
}

@media screen and (max-width: 900px) {
  .three-point .bg02 {
    width: 271px;
    height: 209px;
    background-image: url(../img/top/point_bg02.sp.png);
  }
}

.three-point .heading {
  position: relative;
  text-align: center;
  margin-bottom: 10.29412%;
  z-index: 2;
}

@media screen and (max-width: 900px) {
  .three-point .heading {
    margin-bottom: 100px;
  }
}

.three-point .heading::after {
  content: '';
  display: block;
  width: 35.29412%;
  height: 5px;
  background: #d65259;
  position: absolute;
  right: 0;
  left: 0;
  bottom: -35px;
  margin: auto;
}

@media screen and (max-width: 900px) {
  .three-point .heading::after {
    width: 270px;
    height: 2px;
    bottom: -15px;
  }
}

.three-point .heading img {
  width: 27.58824%;
}

@media screen and (max-width: 900px) {
  .three-point .heading img {
    width: 252px;
  }
}

.three-point .point-list {
  max-width: 1200px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 900px) {
  .three-point .point-list {
    width: 100%;
    display: block;
  }
}

.three-point .point-list .point-item {
  width: 340px;
  border-radius: 15px;
  border: 1px solid #d65259;
  position: relative;
  background: #fff;
}

@media screen and (min-width: 901px) {
  .three-point .point-list .point-item:nth-child(2) {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  .three-point .point-list .point-item:nth-child(3) {
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
  }
}

@media screen and (max-width: 900px) {
  .three-point .point-list .point-item {
    width: 295px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 901px) {
  .three-point .point-list .point-item:not(:first-child) {
    margin-left: 5.29412%;
  }
}

@media screen and (max-width: 900px) {
  .three-point .point-list .point-item:not(:first-child) {
    margin-top: 25px;
  }
}

@media screen and (min-width: 901px) {
  .three-point .point-list .point-item .item-inner {
    padding: 70px 40px 300px;
  }
}

@media screen and (max-width: 900px) {
  .three-point .point-list .point-item .item-inner {
    padding: 40px 125px 45px 25px;
  }
}

.three-point .point-list .point-item .icon {
  text-align: center;
  margin-bottom: 50px;
}

@media screen and (min-width: 901px) {
  .three-point .point-list .point-item .icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 115px;
  }
}

@media screen and (max-width: 900px) {
  .three-point .point-list .point-item .icon {
    margin-bottom: 20px;
  }
}

.three-point .point-list .point-item .ttl {
  text-align: center;
}

@media screen and (min-width: 901px) {
  .three-point .point-list .point-item .ttl {
    font-size: 1.875rem;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 900px) {
  .three-point .point-list .point-item .ttl {
    font-size: 22px;
    margin-bottom: 20px;
  }
}

.three-point .point-list .point-item .text {
  line-height: 2;
}

@media screen and (max-width: 900px) {
  .three-point .point-list .point-item .text {
    font-size: 12px;
  }
}

.three-point .point-list .point-item.item01 img {
  width: 115px;
}

@media screen and (max-width: 900px) {
  .three-point .point-list .point-item.item01 img {
    width: 77px;
  }
}

.three-point .point-list .point-item.item01::after {
  content: '';
  display: block;
  width: 258px;
  height: 383px;
  background: url(../img/top/point01_screen.png) no-repeat center/cover;
  position: absolute;
  left: 0;
  right: -7.35294%;
  bottom: -120px;
  margin: auto;
}

@media screen and (max-width: 900px) {
  .three-point .point-list .point-item.item01::after {
    width: 193px;
    height: 287px;
    left: auto;
    right: -73px;
    bottom: 0;
  }
}

.three-point .point-list .point-item.item02 img {
  width: 115px;
}

@media screen and (max-width: 900px) {
  .three-point .point-list .point-item.item02 img {
    width: 77px;
  }
}

.three-point .point-list .point-item.item02::after {
  content: '';
  display: block;
  width: 258px;
  height: 383px;
  background: url(../img/top/point02_screen.png) no-repeat center/cover;
  position: absolute;
  left: 0;
  right: -7.35294%;
  bottom: -120px;
  margin: auto;
}

@media screen and (max-width: 900px) {
  .three-point .point-list .point-item.item02::after {
    width: 193px;
    height: 287px;
    left: auto;
    right: -73px;
    bottom: 0;
  }
}

.three-point .point-list .point-item.item03 img {
  width: 109px;
}

@media screen and (max-width: 900px) {
  .three-point .point-list .point-item.item03 img {
    width: 77px;
  }
}

.three-point .point-list .point-item.item03::after {
  content: '';
  display: block;
  width: 258px;
  height: 383px;
  background: url(../img/top/point03_screen.png) no-repeat center/cover;
  position: absolute;
  left: 0;
  right: -7.35294%;
  bottom: -120px;
  margin: auto;
}

@media screen and (max-width: 900px) {
  .three-point .point-list .point-item.item03::after {
    width: 193px;
    height: 287px;
    left: auto;
    right: -73px;
    bottom: 0;
  }
}

.btn-area {
  background: #f6f6f6;
  padding: 80px 0;
}

@media screen and (max-width: 900px) {
  .btn-area {
    padding: 30px 0;
  }
}

.registLoginBtn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (min-width: 901px) {
  .registLoginBtn-wrapper .btn:not(:first-child) {
    margin-left: 10px;
  }
}

.registLoginBtn-wrapper .regist-btn {
  width: 400px;
  height: 80px;
  background: #d8b500;
  border-radius: 40px;
  -webkit-transition: background-color .3s ease;
  transition: background-color .3s ease;
}

@media screen and (min-width: 901px) {
  .registLoginBtn-wrapper .regist-btn:hover {
    background: #efca11;
  }
}

@media screen and (max-width: 900px) {
  .registLoginBtn-wrapper .regist-btn {
    width: 150px;
    height: 35px;
    border-radius: 17px 0 0 17px;
  }
}

.registLoginBtn-wrapper .regist-btn .link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;
}

@media screen and (min-width: 901px) {
  .registLoginBtn-wrapper .regist-btn .link {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 900px) {
  .registLoginBtn-wrapper .regist-btn .link {
    font-size: 14px;
  }
}

.registLoginBtn-wrapper .regist-btn .link::before {
  content: '';
  display: block;
  width: 33px;
  height: 25px;
  background: url(../img/top/regist_icon.png) no-repeat center/cover;
  margin-right: 10px;
}

@media screen and (max-width: 900px) {
  .registLoginBtn-wrapper .regist-btn .link::before {
    width: 20px;
    height: 15px;
  }
}

.registLoginBtn-wrapper .login-btn {
  width: 400px;
  height: 80px;
  background: #fff;
  border-radius: 40px;
  border: 1px solid #d8b500;
  -webkit-transition: border-color .3s ease;
  transition: border-color .3s ease;
}

@media screen and (min-width: 901px) {
  .registLoginBtn-wrapper .login-btn:hover {
    border-color: #efca11;
  }
  .registLoginBtn-wrapper .login-btn:hover .link {
    color: #efca11;
  }
  .registLoginBtn-wrapper .login-btn:hover .link::before {
    background-image: url(../img/top/login_icon_h.png);
  }
}

@media screen and (max-width: 900px) {
  .registLoginBtn-wrapper .login-btn {
    width: 150px;
    height: 35px;
    border-radius: 0 17px 17px 0;
  }
}

.registLoginBtn-wrapper .login-btn .link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #d8b500;
  -webkit-transition: color .3s ease;
  transition: color .3s ease;
}

@media screen and (min-width: 901px) {
  .registLoginBtn-wrapper .login-btn .link {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 900px) {
  .registLoginBtn-wrapper .login-btn .link {
    font-size: 14px;
  }
}

.registLoginBtn-wrapper .login-btn .link::before {
  content: '';
  display: block;
  width: 24px;
  height: 26px;
  background: url(../img/top/login_icon.png) no-repeat center/cover;
  margin-right: 10px;
  -webkit-transition: background-image .3s ease;
  transition: background-image .3s ease;
}

@media screen and (max-width: 900px) {
  .registLoginBtn-wrapper .login-btn .link::before {
    width: 15px;
    height: 16px;
  }
}

.app-btn-top {
  position: absolute;
  margin-top: -15%;
  width: 48%;
}

.app-btn-top a {
  margin-right: 32px;
}

.app-video-area {
  margin-top: 25px;
}

@media screen and (max-width: 900px) {
  .app-btn-center {
    position: relative;
    text-align: center;
    padding-top: 25px;
    height: 200px;
    background-image: url(../img/header/main_visual.sp.png);
    z-index: 2;
  }
}

@media screen and (min-width: 901px) {
  .app-btn-center {
    width: 100%;
    height: 200px;
    text-align: center;
    padding: 0;
    background: url(../img/header/main_visual.png) no-repeat center/cover;
  }
  .app-btn-center .app-btn-center-box {
    width: 60%;
    height: 200px;
    position: absolute;
    left: 4.41176%;
  }
  .app-btn-center .app-btn-center-box img {
    margin-top: 10px;
  }
  .app-btn-center .sumaho {
    display: block;
    position: absolute;
    width: 32.82353%;
    height: 200px;
    right: 4%;
    background: url(../img/header/app_parts.png) no-repeat center/cover;
    z-index: 1;
  }
  .app-btn-center .sumaho::before {
    content: '';
    display: block;
    width: 100%;
    height: 300px;
  }
}

.app-btn-center .app-ttl {
  font-size: 2em;
  text-align: center;
  font-weight: bold;
  color: #F2F2F2;
  padding-top: 25px;
  padding-bottom: 25px;
  text-shadow: 1px 2px 3px #756F70;
}

.app-btn-center a {
  max-width: 80%;
  height: auto;
}

.app-btn-center img {
  max-width: 80%;
  height: auto;
  margin-left: 20px;
  margin-right: 20px;
}

.comming-soon {
  padding-top: 5.88235%;
  padding-bottom: 7.64706%;
}

@media screen and (max-width: 900px) {
  .comming-soon {
    padding: 40px 0;
  }
}

.comming-soon .inner {
  width: 70.58824%;
  margin: 0 auto;
}

@media screen and (max-width: 900px) {
  .comming-soon .inner {
    width: 295px;
  }
}

.ninpath-intro {
  padding-top: 17%;
  padding-bottom: 10.29412%;
  background: #f7f7f7;
  position: relative;
}

@media screen and (max-width: 900px) {
  .ninpath-intro {
    padding-bottom: 60px;
    padding-top: 22%;
  }
}

.ninpath-intro .wave {
  display: block;
  width: 100%;
  background: url(../img/top/wave.png) no-repeat center/cover;
  position: absolute;
  left: 0;
  top: 0;
}

@media screen and (min-width: 901px) {
  .ninpath-intro .wave::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 17%;
  }
}

@media screen and (max-width: 900px) {
  .ninpath-intro .wave {
    background-image: url(../img/top/wave.sp.png);
    height: auto;
    padding-top: 22%;
  }
}

.ninpath-intro .heading {
  text-align: center;
  margin-bottom: 125px;
  padding-top: 15px;
}

@media screen and (max-width: 900px) {
  .ninpath-intro .heading {
    margin-bottom: 35px;
  }
}

.ninpath-intro .heading img {
  width: 19.11765%;
}

@media screen and (max-width: 900px) {
  .ninpath-intro .heading img {
    width: 135px;
  }
}

.ninpath-intro .column {
  position: relative;
}

@media screen and (min-width: 901px) {
  .ninpath-intro .column::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 41.17647%;
  }
}

@media screen and (max-width: 900px) {
  .ninpath-intro .column {
    height: auto;
    padding: 0 8%;
  }
}

.ninpath-intro .col01 {
  bottom: 100px;
  margin-bottom: 5.88235%;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  opacity: 0;;
  -webkit-transition: opacity .5s ease, -webkit-transform ease .5s;
  transition: opacity .5s ease, -webkit-transform ease .5s;
  transition: opacity .5s ease, transform ease .5s;
  transition: opacity .5s ease, transform ease .5s, -webkit-transform ease .5s;
}

@media screen and (max-width: 900px) {
  .ninpath-intro .col01 {
    margin-bottom: 160px;
  }
}

.ninpath-intro .col01.is-active {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.ninpath-intro .col01.is-active .image img {
  opacity: 1;
}

.ninpath-intro .col01.is-active .image .squre {
  opacity: 1;
}

.ninpath-intro .col01 .white-box {
  width: 67.64706%;
  background: #fff;
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (min-width: 901px) {
  .ninpath-intro .col01 .white-box::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 60.86957%;
  }
}

@media screen and (max-width: 900px) {
  .ninpath-intro .col01 .white-box {
    position: static;
    width: 100%;
    height: auto;
    padding-top: 20px;
    padding-left: 5.33333%;
    padding-right: 5.33333%;
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 901px) {
  .ninpath-intro .col01 .white-box .contents {
    position: absolute;
    top: 10.43478%;
    left: 26.08696%;
  }
}

.ninpath-intro .col01 .white-box .ttl {
  font-weight: 700;
}

@media screen and (min-width: 901px) {
  .ninpath-intro .col01 .white-box .ttl {
    line-height: 1.78571;
    margin-bottom: 5.65217%;
  }
}

@media screen and (min-width: 901px) and (min-width: 1200px) {
  .ninpath-intro .col01 .white-box .ttl {
    font-size: calc(1.25rem + ((1vw - 12px) * 1.6));
  }
}

@media screen and (min-width: 901px) and (min-width: 1700px) {
  .ninpath-intro .col01 .white-box .ttl {
    font-size: 28px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1199px) {
  .ninpath-intro .col01 .white-box .ttl {
    font-size: 20px;
  }
}

@media screen and (max-width: 900px) {
  .ninpath-intro .col01 .white-box .ttl {
    font-size: 17px;
    line-height: 1.52941;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 901px) {
  .ninpath-intro .col01 .white-box .text-wrapper .text {
    line-height: 2.35714;
    font-size: 1.125rem;
  }
}

@media screen and (min-width: 901px) and (min-width: 1200px) {
  .ninpath-intro .col01 .white-box .text-wrapper .text {
    font-size: calc(0.75rem + ((1vw - 12px) * 1.2));
  }
}

@media screen and (min-width: 901px) and (min-width: 1700px) {
  .ninpath-intro .col01 .white-box .text-wrapper .text {
    font-size: 18px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1199px) {
  .ninpath-intro .col01 .white-box .text-wrapper .text {
    font-size: 12px;
  }
}

@media screen and (max-width: 900px) {
  .ninpath-intro .col01 .white-box .text-wrapper .text {
    line-height: 2.08333;
    font-size: 12px;
  }
}

.ninpath-intro .col01 .white-box .text-wrapper .text:not(:first-child) {
  margin-top: 3.47826%;
}

@media screen and (max-width: 900px) {
  .ninpath-intro .col01 .white-box .text-wrapper .text:not(:first-child) {
    margin-top: 30px;
  }
}

.ninpath-intro .col01 .image {
  width: 39.13043%;
  position: absolute;
  top: 23.52941%;
  left: -22.17391%;
  margin: auto;
}

@media screen and (min-width: 901px) {
  .ninpath-intro .col01 .image::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 66.66667%;
  }
}

@media screen and (max-width: 900px) {
  .ninpath-intro .col01 .image {
    width: 243px;
    height: 161px;
    left: 0;
    right: 0;
    top: auto;
    bottom: -80px;
  }
}

.ninpath-intro .col01 .image img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
  -webkit-transition: opacity 1s ease-in-out .5s;
  transition: opacity 1s ease-in-out .5s;
}

.ninpath-intro .col01 .image .squre {
  display: block;
  width: 26.66667%;
  background: #76d1d9;
  position: absolute;
  bottom: -13.33333%;
  left: -13.33333%;
  z-index: 1;
  opacity: 0;;
  -webkit-transition: opacity 1s ease-in-out .7s;
  transition: opacity 1s ease-in-out .7s;
}

@media screen and (min-width: 901px) {
  .ninpath-intro .col01 .image .squre::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
  }
}

@media screen and (max-width: 900px) {
  .ninpath-intro .col01 .image .squre {
    width: 60px;
    height: 60px;
    bottom: -30px;
    left: -30px;
  }
}

.ninpath-intro .col02 {
  bottom: 100px;
  margin-bottom: 7.35294%;
  opacity: 0;;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: opacity .5s ease, -webkit-transform .5s ease;
  transition: opacity .5s ease, -webkit-transform .5s ease;
  transition: opacity .5s ease, transform .5s ease;
  transition: opacity .5s ease, transform .5s ease, -webkit-transform .5s ease;
}

@media screen and (min-width: 901px) {
  .ninpath-intro .col02::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 57.64706%;
  }
}

@media screen and (max-width: 900px) {
  .ninpath-intro .col02 {
    height: auto;
    margin-bottom: 275px;
  }
}

.ninpath-intro .col02.is-active {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.ninpath-intro .col02.is-active .image {
  opacity: 1;
}

.ninpath-intro .col02.is-active .image img {
  opacity: 1;
}

.ninpath-intro .col02 .white-box {
  width: 67.64706%;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
}

@media screen and (min-width: 901px) {
  .ninpath-intro .col02 .white-box::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 85.21739%;
  }
}

@media screen and (max-width: 900px) {
  .ninpath-intro .col02 .white-box {
    width: 100%;
    height: auto;
    position: static;
    padding-top: 20px;
    padding-left: 5.33333%;
    padding-right: 5.33333%;
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 901px) {
  .ninpath-intro .col02 .white-box .contents {
    position: absolute;
    left: 21.73913%;
    top: 11.73913%;
  }
}

.ninpath-intro .col02 .white-box .ttl {
  font-weight: 700;
}

@media screen and (min-width: 901px) {
  .ninpath-intro .col02 .white-box .ttl {
    line-height: 1.78571;
    margin-bottom: 5.65217%;
  }
}

@media screen and (min-width: 901px) and (min-width: 1200px) {
  .ninpath-intro .col02 .white-box .ttl {
    font-size: calc(1.25rem + ((1vw - 12px) * 1.6));
  }
}

@media screen and (min-width: 901px) and (min-width: 1700px) {
  .ninpath-intro .col02 .white-box .ttl {
    font-size: 28px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1199px) {
  .ninpath-intro .col02 .white-box .ttl {
    font-size: 20px;
  }
}

@media screen and (max-width: 900px) {
  .ninpath-intro .col02 .white-box .ttl {
    font-size: 17px;
    line-height: 1.52941;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 901px) {
  .ninpath-intro .col02 .white-box .text-wrapper .text {
    line-height: 2.35714;
    font-size: 1.125rem;
  }
}

@media screen and (min-width: 901px) and (min-width: 1200px) {
  .ninpath-intro .col02 .white-box .text-wrapper .text {
    font-size: calc(0.75rem + ((1vw - 12px) * 1.2));
  }
}

@media screen and (min-width: 901px) and (min-width: 1700px) {
  .ninpath-intro .col02 .white-box .text-wrapper .text {
    font-size: 18px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1199px) {
  .ninpath-intro .col02 .white-box .text-wrapper .text {
    font-size: 12px;
  }
}

@media screen and (max-width: 900px) {
  .ninpath-intro .col02 .white-box .text-wrapper .text {
    line-height: 2.08333;
    font-size: 12px;
  }
}

.ninpath-intro .col02 .white-box .text-wrapper .text:not(:first-child) {
  margin-top: 3.47826%;
}

@media screen and (max-width: 900px) {
  .ninpath-intro .col02 .white-box .text-wrapper .text:not(:first-child) {
    margin-top: 30px;
  }
}

.ninpath-intro .col02 .image {
  background: #76d1d9;
  width: 44.11765%;
  position: absolute;
  right: 0;
  top: 28.23529%;
  opacity: 0;;
  -webkit-transition: opacity 1s ease-in-out .5s;
  transition: opacity 1s ease-in-out .5s;
}

@media screen and (min-width: 901px) {
  .ninpath-intro .col02 .image::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 66.66667%;
  }
}

@media screen and (max-width: 900px) {
  .ninpath-intro .col02 .image {
    background: transparent;
    width: 150px;
    height: 239px;
    left: 0;
    top: auto;
    bottom: -160px;
    margin: auto;
  }
  .ninpath-intro .col02 .image::before {
    content: '';
    display: block;
    width: 187px;
    height: 150px;
    background: #76d1d9;
    position: absolute;
    right: -112px;
    bottom: -40px;
    z-index: 1;
  }
}

.ninpath-intro .col02 .image img {
  opacity: 0;;
  -webkit-transition: opacity 1s ease-in-out .7s;
  transition: opacity 1s ease-in-out .7s;
}

@media screen and (min-width: 901px) {
  .ninpath-intro .col02 .image img {
    width: 33.33333%;
    position: absolute;
    right: 0;
    left: 0;
    bottom: -20%;
    margin: auto;
  }
}

@media all and (-ms-high-contrast: none) {
  .ninpath-intro .col02 .image img {
    bottom: -80%;
  }
}

@supports (-ms-ime-align: auto) {
  .ninpath-intro .col02 .image img {
    bottom: -80%;
  }
}

@media screen and (max-width: 900px) {
  .ninpath-intro .col02 .image img {
    position: relative;
    z-index: 2;
  }
}

.carrer .inner {
  width: 70.58824%;
  margin: 0 auto;
  padding-top: 5.88235%;
  padding-bottom: 5.29412%;
}

@media screen and (max-width: 900px) {
  .carrer .inner {
    width: 100%;
    padding: 60px 8% 75px;
  }
}

.carrer .flex-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .carrer .flex-wrapper {
    display: block;
  }
}

.carrer .flex-wrapper .contents {
  width: 50%;
}

@media screen and (max-width: 900px) {
  .carrer .flex-wrapper .contents {
    width: 100%;
  }
  .carrer .flex-wrapper .contents::before {
    content: '';
    display: block;
    width: 218px;
    height: 157px;
    background: url(../img/top/carrer_screen.sp.png) no-repeat center/cover;
    margin: 0 auto;
  }
}

.carrer .flex-wrapper .contents .ttl {
  font-weight: bold;
}

@media screen and (min-width: 901px) {
  .carrer .flex-wrapper .contents .ttl {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 901px) and (min-width: 1200px) {
  .carrer .flex-wrapper .contents .ttl {
    font-size: calc(1.125rem + ((1vw - 12px) * 1.4));
  }
}

@media screen and (min-width: 901px) and (min-width: 1700px) {
  .carrer .flex-wrapper .contents .ttl {
    font-size: 25px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1199px) {
  .carrer .flex-wrapper .contents .ttl {
    font-size: 18px;
  }
}

@media screen and (max-width: 900px) {
  .carrer .flex-wrapper .contents .ttl {
    text-align: center;
    font-size: 16px;
    margin-bottom: 15px;
  }
}

.carrer .flex-wrapper .contents .name {
  width: 70.83333%;
  margin-bottom: 40px;
}

@media screen and (max-width: 900px) {
  .carrer .flex-wrapper .contents .name {
    margin: 0 auto 25px;
    width: 235px;
  }
}

.carrer .flex-wrapper .contents .text {
  line-height: 2.26667;
}

@media screen and (min-width: 1200px) {
  .carrer .flex-wrapper .contents .text {
    font-size: calc(0.75rem + ((1vw - 12px) * 0.8));
  }
}

@media screen and (min-width: 1700px) {
  .carrer .flex-wrapper .contents .text {
    font-size: 16px;
  }
}

@media screen and (max-width: 1199px) {
  .carrer .flex-wrapper .contents .text {
    font-size: 12px;
  }
}

@media screen and (min-width: 901px) {
  .carrer .flex-wrapper .thumb {
    width: 50%;
    padding-left: 8.33333%;
  }
}

.carrer .flex-wrapper .thumb .image {
  position: relative;
  width: 72.16667%;
  overflow: hidden;
  margin: 0 auto 30px;
}

@media screen and (min-width: 901px) {
  .carrer .flex-wrapper .thumb .image::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 58.89145%;
  }
}

@media screen and (max-width: 900px) {
  .carrer .flex-wrapper .thumb .image {
    display: none;
  }
}

.carrer .flex-wrapper .thumb .image img {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

.carrer .flex-wrapper .thumb .btn {
  width: 83.33333%;
  height: 65px;
  border-radius: 32px;
  border: 1px solid #212957;
  -webkit-transition: background-color .3s ease;
  transition: background-color .3s ease;
}

@media screen and (min-width: 901px) {
  .carrer .flex-wrapper .thumb .btn {
    margin: 0 auto;
  }
  .carrer .flex-wrapper .thumb .btn:hover {
    background: #212957;
  }
  .carrer .flex-wrapper .thumb .btn:hover .link {
    color: #fff;
  }
  .carrer .flex-wrapper .thumb .btn:hover .link::before {
    background-image: url(../img/top/arrow_h.png);
  }
}

@media screen and (max-width: 900px) {
  .carrer .flex-wrapper .thumb .btn {
    width: 305px;
    height: 50px;
    margin: 30px auto 0;
  }
}

.carrer .flex-wrapper .thumb .btn .link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #212957;
  -webkit-transition: color .3s ease;
  transition: color .3s ease;
}

@media screen and (min-width: 901px) {
  .carrer .flex-wrapper .thumb .btn .link {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 900px) {
  .carrer .flex-wrapper .thumb .btn .link {
    font-size: 12px;
  }
}

.carrer .flex-wrapper .thumb .btn .link::before {
  content: '';
  display: block;
  width: 21px;
  height: 22px;
  background: url(../img/top/arrow.png) no-repeat center/cover;
  margin-right: 10px;
  -webkit-transition: background-image .3s ease;
  transition: background-image .3s ease;
}

@media screen and (max-width: 900px) {
  .carrer .flex-wrapper .thumb .btn .link::before {
    width: 17px;
    height: 17px;
  }
}

@media screen and (min-width: 901px) {
  .middle-banners {
    padding-left: 30px;
    text-align: center;
    background: #f6f6f6;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .middle-banners img {
    width: 80%;
    margin: 0;
  }
}
@media screen and (max-width: 900px) {
  .middle-banners {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 5px;
    text-align: center;
  }
  .middle-banners img {
    width: 100%;
  }
}


.footer-banners .inner {
  width: 70.58824%;
  margin: 0 auto;
  padding-top: 2.88235%;
  padding-bottom: 5.29412%;
}

@media screen and (max-width: 900px) {
  .footer-banners .inner {
    width: 100%;
    padding: 40px 8% 75px;
  }
}

.footer-banners .flex-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .footer-banners .flex-wrapper {
    display: block;
  }
}

.footer-banners .flex-wrapper .contents {
  width: 50%;
}

@media screen and (max-width: 900px) {
  .footer-banners .flex-wrapper .contents {
    width: 100%;
  }
  .footer-banners .flex-wrapper .contents::before {
    content: '';
    display: block;
    width: 360px;
    height: 157px;
    background: url(../img/hoken/hoken_image.png) no-repeat center/contain;
    margin: 0 auto;
  }
}

.footer-banners .flex-wrapper .contents .ttl {
  font-weight: bold;
}

@media screen and (min-width: 901px) {
  .footer-banners .flex-wrapper .contents .ttl {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 901px) and (min-width: 1200px) {
  .footer-banners .flex-wrapper .contents .ttl {
    font-size: calc(1.125rem + ((1vw - 12px) * 1.4));
  }
}

@media screen and (min-width: 901px) and (min-width: 1700px) {
  .footer-banners .flex-wrapper .contents .ttl {
    font-size: 25px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1199px) {
  .footer-banners .flex-wrapper .contents .ttl {
    font-size: 18px;
  }
}

@media screen and (max-width: 900px) {
  .footer-banners .flex-wrapper .contents .ttl {
    text-align: center;
    font-size: 16px;
    margin-bottom: 15px;
  }
}

.footer-banners .flex-wrapper .contents .name {
  margin-top: 32px;
  font-size: 32px;
  line-height: 1.25;
  text-align: left;
  font-weight: bold;
  margin-bottom: 40px;
}

@media screen and (max-width: 900px) {
  .footer-banners .flex-wrapper .contents .name {
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    margin: 0 auto 25px;
    width: 80%;
  }
}

.footer-banners .flex-wrapper .contents .text {
  line-height: 2.26667;
}

.footer-banners .flex-wrapper .contents .annotation {
  line-height: 1.75;
  font-size: 0.8rem;
}

@media screen and (min-width: 1200px) {
  .footer-banners .flex-wrapper .contents .text {
    font-size: calc(0.75rem + ((1vw - 12px) * 0.8));
  }
}

@media screen and (min-width: 1700px) {
  .footer-banners .flex-wrapper .contents .text {
    font-size: 16px;
  }
}

@media screen and (max-width: 1199px) {
  .footer-banners .flex-wrapper .contents .text {
    font-size: 12px;
  }
}

@media screen and (min-width: 901px) {
  .footer-banners .flex-wrapper .thumb {
    width: 50%;
    padding-left: 8.33333%;
  }
}

.footer-banners .flex-wrapper .thumb .image {
  position: relative;
  width: 72.16667%;
  overflow: hidden;
  margin: 0 auto 30px;
}

@media screen and (min-width: 901px) {
  .footer-banners .flex-wrapper .thumb .image::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 58.89145%;
  }
}

@media screen and (max-width: 900px) {
  .footer-banners .flex-wrapper .thumb .image {
    display: none;
  }
}

.footer-banners .flex-wrapper .thumb .image img {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

.footer-banners .flex-wrapper .thumb .btn {
  width: 83.33333%;
  height: 65px;
  border-radius: 32px;
  border: 1px solid #212957;
  -webkit-transition: background-color .3s ease;
  transition: background-color .3s ease;
}

@media screen and (min-width: 901px) {
  .footer-banners .flex-wrapper .thumb .btn {
    margin: 0 auto;
  }
  .footer-banners .flex-wrapper .thumb .btn:hover {
    background: #212957;
  }
  .footer-banners .flex-wrapper .thumb .btn:hover .link {
    color: #fff;
  }
  .footer-banners .flex-wrapper .thumb .btn:hover .link::before {
    background-image: url(../img/top/arrow_h.png);
  }
}

@media screen and (max-width: 900px) {
  .footer-banners .flex-wrapper .thumb .btn {
    width: 305px;
    height: 50px;
    margin: 30px auto 0;
  }
}

.footer-banners .flex-wrapper .thumb .btn .link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #212957;
  -webkit-transition: color .3s ease;
  transition: color .3s ease;
}

@media screen and (min-width: 901px) {
  .footer-banners .flex-wrapper .thumb .btn .link {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 900px) {
  .footer-banners .flex-wrapper .thumb .btn .link {
    font-size: 12px;
  }
}

.footer-banners .flex-wrapper .thumb .btn .link::before {
  content: '';
  display: block;
  width: 21px;
  height: 22px;
  background: url(../img/top/arrow.png) no-repeat center/cover;
  margin-right: 10px;
  -webkit-transition: background-image .3s ease;
  transition: background-image .3s ease;
}

@media screen and (max-width: 900px) {
  .footer-banners .flex-wrapper .thumb .btn .link::before {
    width: 17px;
    height: 17px;
  }
}

@media screen and (min-width: 901px) {
  .footer-banners .flex-wrapper .thumb .text {
    margin-top: 16px;
    font-size: 1rem;
    text-align: center;
  }
}

@media screen and (max-width: 900px) {
  .footer-banners .flex-wrapper .thumb .text {
    margin-top: 16px;
    font-size: 0.8rem;
    text-align: center;
  }
}

/* パソコンで見たときは"pc"のclassがついた画像が表示される */
.pc { display: block !important; }
.sp { display: none !important; }
/* スマートフォンで見たときは"sp"のclassがついた画像が表示される */
@media only screen and (max-width: 900px) {
.pc { display: none !important; }
.sp { display: block !important; }
}